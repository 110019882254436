import React, { useContext } from 'react';
import './theme.css';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import { GlobalContext } from '../../context/globalContext';

function Theme() {
  const { theme } = useContext(GlobalContext);
  const { handleToggle, isShaking } = useContext(GlobalContext);

  return (
    <div className="theme-container">
      {theme === 'dark' ? (
        <LightModeIcon
          onClick={handleToggle}
          style={{ color: 'white', fontSize: '35px' }}
          className={`${!isShaking ? 'shake' : ''}`}
        />
      ) : (
        <NightlightRoundIcon
          onClick={handleToggle}
          style={{ color: 'black', fontSize: '35px' }}
          className={`${!isShaking ? 'shake' : ''}`}
        />
      )}
    </div>
  );
}

export default Theme;
