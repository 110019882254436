import React from 'react';
import './home.css';
import Profile from '../../assets/latifa-profile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

function Home() {
  return (
    <section id="home" className="home-container">
      <div className="profile-container">
        <img className="profile" src={Profile} alt="profile-pic" />
      </div>
      <div className="text-icon-container">
        <h2 className="text">
          Hello, I'm
          <br />
          <span className="emily-clark">Latifa Yari</span>
          <br />
          And I'm a <span className="neonText">Data Analyst</span>
        </h2>
        <p className="text description-text">
          Data analyst passionate about transforming raw data into actionable
          insights. Expert in visualizing trends, optimizing processes, and
          solving problems.
        </p>
        <div className="media">
          <a
            href="https://www.instagram.com/latifa_y24"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="neon-icon" icon={faInstagram} />
          </a>

          <a
            href="https://www.facebook.com/latifa.yaree"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="neon-icon" icon={faFacebook} />
          </a>
          <a
            href="https://www.linkedin.com/in/latifa-yari-b67982201"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="neon-icon" icon={faLinkedin} />
          </a>
        </div>
        <a href="/LatifaYari-cv.pdf" download>
          <button className="download-button">Download CV</button>
        </a>
      </div>
    </section>
  );
}

export default Home;
