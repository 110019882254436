import React, { useContext } from 'react';
import './outlet.css';
import SideNav from '../sideNav/SideNav';
import { GlobalContext } from '../../context/globalContext';
import Home from '../home/Home';
import About from '../about/About';
import Services from '../services/Services';
import Projects from '../projects/Projects';
import Contact from '../contact/Contact';

const Outlet = () => {
  const { sideNavOpen } = useContext(GlobalContext);
  return (
    <div className="outlet-container">
      {sideNavOpen && <SideNav />}
      <Home />
      <About />
      <Services />
      <Projects />
      <Contact />
    </div>
  );
};

export default Outlet;
