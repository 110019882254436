import React from 'react';
import './about.css';
import Profile from '../../assets/latifa-profile.png';

function About() {
  return (
    <section id="about" className="about-container">
      <div className="about-profile-container">
        <img className="profile" src={Profile} alt="profile-pic" />
      </div>
      <div className="about-text-icon-container">
        <h2 className="text">
          <span className="emily-clark">
            About
            <span className="neonText"> Me</span>
          </span>
          <br />
          Data Analyst
        </h2>
        <p className="text">
          Data analyst passionate about transforming raw data into actionable
          insights. Expert in visualizing trends, optimizing processes, and
          solving problems.
        </p>
        <a href="/LatifaYari-cv.pdf" download>
          <button className="download-button">Download CV</button>
        </a>
      </div>
    </section>
  );
}

export default About;
