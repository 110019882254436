import { React, useContext } from 'react';
import './contact.css';
import { GlobalContext } from '../../context/globalContext';

function Contact() {
  const { formData, handleFormChange } = useContext(GlobalContext);
  return (
    <section id="contact" className="contact-container">
      <h2 className="text-container">
        Contact <span className="neonText">Me!</span>
      </h2>

      <form
        className="contact-form"
        action="https://formsubmit.co/yarilatifa@gmail.com"
        method="POST"
      >
        <div className="form-group">
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleFormChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleFormChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="EmailSubject">Email Subject</label>
          <input
            type="text"
            id="EmailSubject"
            name="EmailSubject"
            value={formData.EmailSubject}
            onChange={handleFormChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="PhoneNumber">Phone Number</label>
          <input
            type="number"
            id="PhoneNumber"
            name="PhoneNumber"
            value={formData.PhoneNumber}
            onChange={handleFormChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleFormChange}
            required
          />
        </div>

        <button type="submit" className="submit-button">
          Send Message
        </button>
      </form>
    </section>
  );
}

export default Contact;
