import React from 'react';
import './projects.css';
import ProjectOne from '../../assets/project-1.jpg';
import ProjectTwo from '../../assets/project-2.jpg';
import ProjectThree from '../../assets/project-3.jpg';

function Projects() {
  return (
    <section id="projects" className="projects-container">
      <h2>
        Latest <span className="neonText">Projects</span>
      </h2>
      <div className="services-box-container">
        <div className="service-box">
          <img className="project-img" src={ProjectOne} alt="Project-1" />
        </div>
        <div className="service-box">
          <img className="project-img" src={ProjectTwo} alt="Project-2" />
        </div>
        <div className="service-box">
          <img className="project-img" src={ProjectThree} alt="Project-3" />
        </div>
      </div>
    </section>
  );
}

export default Projects;
