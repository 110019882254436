import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Header from './components/header/Header';
import Outlet from './components/outlet/Outlet';

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
  },
]);

function App() {
  return (
    <div className="App">
      <header className="container">
        <RouterProvider router={router} />
      </header>
    </div>
  );
}

export default App;
