import React from 'react';
import './sideNav.css';

const SideNav = () => {
  return (
    <div className="sideNavContainer">
      <a href="#home">
        <h1>Home</h1>
      </a>
      <a href="#about">
        <h1>About</h1>
      </a>
      <a href="#services">
        <h1>Services</h1>
      </a>
      <a href="#projects">
        <h1>Projects</h1>
      </a>
      <a href="#contact">
        <h1>Contact</h1>
      </a>
    </div>
  );
};

export default SideNav;
