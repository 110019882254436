import React from 'react';
import './services.css';
import DataVis from '../../assets/data-vis.png';

function Services() {
  return (
    <section id="services" className="services-container">
      <h2>
        Our <span className="neonText">Services</span>
      </h2>
      <div className="box-container">
        <div className="box">
          <img className="service-icon" src={DataVis} alt="Icon" />
          <p className="description">
            <span className="service">Data Visualizations</span>
            <br />
            <span className="service-description">
              Designing clear, interactive visual dashboards to communicate
              data-driven insights and highlight trends, patterns, and key
              metrics
            </span>
          </p>
        </div>
        <div className="box">
          <img className="service-icon" src={DataVis} alt="Icon" />
          <p className="description">
            <span className="service">Data Analysis</span>
            <br />
            <span className="service-description">
              Transforming raw datasets by removing errors, handling missing
              values, and organizing data for accurate, efficient analysis
            </span>
          </p>
        </div>
        <div className="box">
          <img className="service-icon" src={DataVis} alt="Icon" />
          <p className="description">
            <span className="service">Statistical Modeling </span>
            <br />
            <span className="service-description">
              Applying statistical techniques and machine learning models to
              predict outcomes, identify trends, and guide decision-making
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
