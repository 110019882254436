import React, { createContext, useState, useEffect } from 'react';

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  // Theme State Management
  const storedTheme = localStorage.getItem('theme') || 'dark';
  const [theme, setTheme] = useState(storedTheme);

  // form state management
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    project: '',
    service: '',
    message: '',
  });

  // handle form function
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Open/Close State Management
  const [sideNavOpen, setsideNavOpen] = useState(false);

  const [iconClass, setIconClass] = useState('light');

  const [isShaking, setIsShaking] = useState(false);

  const handleToggle = () => {
    if (theme === 'light') {
      setIsShaking(true);
      setTimeout(() => {
        setIsShaking(false);
      }, 1000); // Duration of the shake animation
    }
    toggleTheme();
  };

  // Toggle theme function
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  // Update localStorage whenever the them changes
  useEffect(() => {
    document.body.className = theme;
    localStorage.setItem('theme', theme);
    setIconClass(theme === 'dark' ? 'icon-light' : 'icon-dark');
  }, [theme]);

  // Toggle open/close function
  const toggle = () => {
    setsideNavOpen(!sideNavOpen);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme,
        toggle,
        sideNavOpen,
        handleToggle,
        iconClass,
        formData,
        handleFormChange,
        isShaking,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalProvider, GlobalContext };
