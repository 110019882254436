import { React, useState, useEffect } from 'react';
import './header.css';
import Menu from '../menu/Menu';
import Theme from '../theme/Theme';

function Header() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${scrollPosition > 0 ? 'scrolled' : ''}`}>
      <div className="name">
        <h1>
          <span className="pink-color">Latifa</span> Yari
        </h1>
      </div>

      <div className="icons">
        <div className="nav-container">
          <a href="#home">
            <h1>Home</h1>
          </a>
          <a href="#about">
            <h1>About</h1>
          </a>
          <a href="#services">
            <h1>Services</h1>
          </a>
          <a href="#projects">
            <h1>Projects</h1>
          </a>
          <a href="#contact">
            <h1>Contact</h1>
          </a>
        </div>
        <Theme />
        <Menu />
      </div>
    </nav>
  );
}

export default Header;
