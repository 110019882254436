import React, { useContext } from 'react';
import './menu.css';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { GlobalContext } from '../../context/globalContext';

function Menu() {
  const { toggle, sideNavOpen, iconClass } = useContext(GlobalContext);
  return (
    <div className="menu-container">
      {!sideNavOpen ? (
        <MenuIcon
          onClick={toggle}
          style={{ fontSize: '35px' }}
          className={iconClass}
        />
      ) : (
        <CloseIcon
          onClick={toggle}
          style={{ fontSize: '35px' }}
          className={iconClass}
        />
      )}
    </div>
  );
}

export default Menu;
